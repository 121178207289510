// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import Header from './Header';    // Pour importer un fichier 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './App.css';


function Header() {
    return (
        <header className='header'>
            <h1 className='titre'>Portfolio <span id="nom">Ludovic SERRURIER</span></h1><p>
                <img src='../images/ludo.jpg' alt="Developpeur de Ludo" class="pic"/></p>
                {/* <button id="encre">DART</button> */}

            <nav>
               {/* <Link to="/">Liste de projets réalisés </Link> */}
                {/* <Link to="/about"> À propos de moi</Link> */}
                {/*<Link to="/contact"> Contact</Link>*/}
            </nav>
        </header>
    );
}

function TimelineEvent({ date, content }) {
    return (
    
        <div className="timeline-event">
            <div className="timeline-date">{date}</div>
            <div className="timeline-content">{content}</div>
        </div>
    );
}


function Accueil() {
    return (
        <div>
            <h1>Présentation</h1>
            <div className="texte">Bonjour à vous, je vous présente ci-dessous une liste de projets que j'ai réalisé. </div>
            <div className="texte">Ils sont présentées par ordre chronologique, de la plus récente à la plus ancienne. </div>  
            <div className="texte">Elles vous permettent de voir une partie de mes connaissances et expériences professionnelles dans ce domaine. Je m'adapte aux différentes technologies. </div> 
            <div className="texte">Je réponds a toutes vos demandes pour vous apporter une solution rapide et efficace. </div> 
            <div className="texte">Je peux travailler au TJM ou au forfait en fonction de votre demande. </div> 
            <div className="texte">Contactez-moi a cette adresse mail : <b> sludovic38@gmail.com</b> pour votre demande. Réponses en moins de 24h du lundi ou vendredi voir le samedi si je suis disponible. </div> <br></br><p></p><br></br>
 
        
            <button id="custom">DART
            </button>
            <button id="custom">FLUTTER</button>
            <button id="custom">REACT JS </button>
            <button id="custom">NODE JS</button>
            <button id="custom">JAVASCRIPT</button>
            <button id="custom">SOCKET IO</button>
            <button id="custom">EXPRESS</button>
            <button id="custom">PHP</button>
            <button id="custom">PHP MY ADMIN</button>
            <button id="custom">MARIA DB</button>
            <button id="custom">SQL</button>
            <button id="custom">JSON</button>
            <button id="custom">CURL</button>
            <button id="custom">STRIPE</button>
            <button id="custom">API</button>
            <button id="custom">AJAX</button>
            <button id="custom">JQUERY</button>
            <button id="custom">FILEZILLA</button>
            <button id="custom">SHELL</button>
            <button id="custom">PLESK</button>
            <button id="custom">LETS ENCRYPT</button>
            <button id="custom">FTP</button>
            <button id="custom">WORDPRESS</button>
            <button id="custom">ORGANISATION</button>
            <button id="custom">QUALITE</button>
            <button id="custom">PHOTOSHOP</button>
            <button id="custom">VEILLE INFORMATIQUE</button>
            <button id="custom">CSV</button>
            <button id="custom">MAMP</button>
            <button id="custom">WAMP</button>
            <button id="custom">LOOKER STUDIO </button>
            <button id="custom">SSL</button>
            <button id="custom">IOS</button>
            <button id="custom">ANDROID </button>
            <button id="custom">SAUVEGARDE AUTOMATIQUE </button>
            <button id="custom">HTML</button>
            <button id="custom">CSS</button> <br></br>
            <button id="custom">FFMPEG</button>


            <div className="timeline-container">
                <h3>Réalisations </h3>
                <p> Images extraites de mon application. En cours de réalisation. temps de travail 6 mois. Réalisation entière front-end & back-end. Compatible IOS / ANDROID voir web par la suite.</p>

                <TimelineEvent 
                date=" 2023 - 2024 " 
                content={
                    <div>
                        <div className="image-gallery">
                      
                            <img src='../images/appli4.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli1.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli2.png' alt="Developpeur de Ludo" />

                            <img src='../images/appli5.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli6.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli7.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli8.png' alt="Developpeur de Ludo" />
                            <img src='../images/appli9.png' alt="Developpeur de Ludo" />
 
                            {/* ... ajoutez d'autres images si nécessaire */}
                        </div>
                     </div>
                } 
            />

            <br/><a href="https://easydate.io" target='blank'>easydate.io</a><p/>
            <p>Site de rencontre easydate.io avec recherche géolocalisé et intégration d'un service de visio-conférences. </p>
            <TimelineEvent date="01 Fev 2022" content={
                <div>
                    <iframe 
                        src="https://easydate.io"
                        width="100%" 
                        height="1000" 
                        title="Description de l'iframe"
                        frameborder="0" 
                        allowfullscreen>
                   </iframe>
                </div>
                } 
            />


            <p><a href="https://visiocam.easydate.io" target='blank' >visiocam.easydate.io</a></p>
            <p>Site de visio-conférence intégré également à easydate.io</p>

              <TimelineEvent 
              date="01 Janv 2021" content={
                <div>
                    {/* <iframe 
                        src="https://visiocam.easydate.io"
                        width="100%" 
                        height="700" 
                        title="Description de l'iframe"
                        frameborder="0" 
                        allowfullscreen>
                   </iframe> */}

<img src='../images/visiocam.jpg' alt="Developpeur de Ludo"  style={{ display: 'block', margin: 'auto',width: '60%',height:'1000' }} />

                </div>
                } 
            />



<p><a href="https://copyright-world.com" target='blank'>copyright-world.com</a></p>
<p>Site pour créer des certificats d'horatage de documents avec des clés de hachage.</p>

              <TimelineEvent date="15 mars 2020" content={
                <div>
                    <iframe 
                    
                        src="https://copyright-world.com"
                        width="100%" 
                        height="1000" 
                        title="Description de l'iframe"
                        frameborder="0" 
                        allowfullscreen>
                   </iframe>
                  
                </div>
                } />
                <TimelineEvent date="1 sept 2021" content="Importation de donnée CSV dans phpmyadmin " />

                <TimelineEvent date="1 avril 2021" content="Réalisation de site wordpress" />

                <TimelineEvent date="1 Mars 2020" content="Application web pour compresser les fichiers vidéos et images" />

                {/* ... vous pouvez ajouter d'autres événements ici */}<p></p><br></br>

      
    
    <div class='footer'>
      <FontAwesomeIcon icon={faMapMarkerAlt} /><span> 74 200 Thonon les bains, France </span><br></br>
      <FontAwesomeIcon icon={faEnvelope} /><span>  sludovic38@gmail.com </span><br></br>
  
    <div className="status-indicator">
      <span className="status-text">Disponible</span>
    </div>

    <a href="https://www.compteurdevisite.com" title="compteur de visite html"><img src="https://counter6.optistats.ovh/private/compteurdevisite.php?c=dhr1lta6st9zns5npael1gb6dxfny3jy" border="0" title="compteur de visite html" alt="compteur de visite html"/></a>  
    
    </div> {/* class footer*/}

           
            </div>


       
        </div> // inclure ci-dessus 
    );
} // accueil 




function About() {
    return <h1>Page à propos</h1>;
}

function Contact() {
    return (
        <div>
            {/* <h1>Page contact</h1> */}
            <p>
             Vous pouvez me contacter depuis cette adresse e-mail pour toute demande : sludovic38@gmail.com
             </p>
             N'hésitez pas !
             
        </div>
    );
}


function App() {
    return (
        <Router>
            <div>
                <Header />
                <Routes>
                    <Route path="/" element={<Accueil />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

 